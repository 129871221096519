<template>
  <b-sidebar
    id="sidebar-apply-sender-id"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Request New Sender ID</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer ref="senderIdFormValidator" #default="{ invalid }">
        <b-form class="p-2" @submit.prevent="applySenderId" ref="senderIdForm">

          <div class="text-center">
            Download Sample Request Letters
            <hr/>
          </div>
          <p class="mb-3">
            Download the request letter samples below to use as a guide during Sender ID request
             <a 
                :href="appUrl + '/uploads/samples/pepea_sms_new_sender_id_application_sample.docx'"
                download
                class="btn-link d-block mt-1"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              >
                <feather-icon
                  icon="FileIcon"
                  class="mr-50"
                />
                <span class="align-middle">Download new Sender ID application sample</span>
              </a>
        
              <a 
                :href="appUrl + '/uploads/samples/pepea_sms_transfer_sender_id_application_sample.doc'"
                download
                class="btn-link d-block mt-1"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              >
                <feather-icon
                  icon="FileIcon"
                  class="mr-50"
                />
                <span class="align-middle">Download transfer of Sender ID application sample</span>
              </a>
          </p>

          <b-form-group label="Name of preferred Sender ID" label-for="title">
              <validation-provider
                #default="{ errors }"
                name="Sender ID"
                vid="title"
                rules="required"
              >
               <b-form-input
                  id="title"
                  v-model="title"
                  name="title"
                  placeholder="Sender ID Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger" v-if="invalidSenderId">Invalid Sender ID, It cannot be more than 11 characters</small>
              </validation-provider>
          </b-form-group>

          <b-form-group label="Choose your request type" label-for="category">
              <validation-provider
                #default="{ errors }"
                name="Type"
                vid="category"
                rules="required"
              >
                <v-select
                    v-model="category"
                    :options="categories"
                    label="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :reduce="(category) => category.value"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>

          <b-form-group label="Attach your application letter file" label-for="letter">
              <validation-provider
                #default="{ errors }"
                name="Letter"
                vid="letter"
                rules="required"
              >
                <b-form-file
                  v-model="letter"
                  id="letter"
                  input-id="letter"
                  placeholder="Choose a MS Word file or drop it here..."
                  drop-placeholder="Drop file here..."
                  accept=".doc,.docm,.docx,.dotx,.odt,.pdf"
                />
                <small class="text-danger d-block">{{ errors[0] }}</small>
              </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex align-items-center justify-content-between">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid || formIsLoading || invalidSenderId"
              :class="{ disabled: invalid }"

            >
              <b-spinner small v-if="formIsLoading" />
              &nbsp;
              Submit
            </b-button>


            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>

    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
  BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from '@themeConfig';


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    vSelect,
    BFormFile,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  emits:["sender-refresh"],
  data(){
    const { appUrl } = $themeConfig.app;
    return{
      title:"",
      category:null,
      letter:null,
      categories:[
          {
              text: "New Sender ID Application",
              value:"new",
          },
          {
              text: "Transfer Sender ID to PepeaSMS",
              value:"transfer",
          }
      ],
      formIsLoading:false,
      appUrl: appUrl,
      required,
    };
  },
  computed:{
    invalidSenderId(){
      return this.title.length > 11;
    },
  },
  methods: {
    applySenderId() {
      this.formIsLoading = true;
      this.$refs.senderIdFormValidator.validate().then(success => {
        if (success) {
          let formData = new FormData();
          formData.append('title', this.title);
          formData.append('category', this.category);
          formData.append('letter', this.letter);
          this.$http.post('/sender-id/create',formData,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
              this.formIsLoading = false;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: response.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit('sender-refresh')
              this.$root.$emit('bv::toggle::collapse', 'sidebar-apply-sender-id')
               this.$nextTick(() => {
                this.$refs.senderIdFormValidator.reset();
              });
              this.category = "";
              this.$refs.senderIdForm.reset();
            })
            .catch(error => {
               for (let err of error.response.data.error) {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title:err,
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                }
                this.formIsLoading = false;
            })
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>