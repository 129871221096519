var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-apply-sender-id","sidebar-class":"sidebar-lg","bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Request New Sender ID")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"senderIdFormValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"senderIdForm",staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.applySenderId.apply(null, arguments)}}},[_c('div',{staticClass:"text-center"},[_vm._v(" Download Sample Request Letters "),_c('hr')]),_c('p',{staticClass:"mb-3"},[_vm._v(" Download the request letter samples below to use as a guide during Sender ID request "),_c('a',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-link d-block mt-1",attrs:{"href":_vm.appUrl + '/uploads/samples/pepea_sms_new_sender_id_application_sample.docx',"download":""}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"FileIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Download new Sender ID application sample")])],1),_c('a',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-link d-block mt-1",attrs:{"href":_vm.appUrl + '/uploads/samples/pepea_sms_transfer_sender_id_application_sample.doc',"download":""}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"FileIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Download transfer of Sender ID application sample")])],1)]),_c('b-form-group',{attrs:{"label":"Name of preferred Sender ID","label-for":"title"}},[_c('validation-provider',{attrs:{"name":"Sender ID","vid":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","name":"title","placeholder":"Sender ID Name"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.invalidSenderId)?_c('small',{staticClass:"text-danger"},[_vm._v("Invalid Sender ID, It cannot be more than 11 characters")]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Choose your request type","label-for":"category"}},[_c('validation-provider',{attrs:{"name":"Type","vid":"category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.categories,"label":"text","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"reduce":function (category) { return category.value; }},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Attach your application letter file","label-for":"letter"}},[_c('validation-provider',{attrs:{"name":"Letter","vid":"letter","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"letter","input-id":"letter","placeholder":"Choose a MS Word file or drop it here...","drop-placeholder":"Drop file here...","accept":".doc,.docm,.docx,.dotx,.odt,.pdf"},model:{value:(_vm.letter),callback:function ($$v) {_vm.letter=$$v},expression:"letter"}}),_c('small',{staticClass:"text-danger d-block"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",class:{ disabled: invalid },attrs:{"variant":"primary","type":"submit","disabled":invalid || _vm.formIsLoading || _vm.invalidSenderId}},[(_vm.formIsLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v("   Submit ")],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" Cancel ")])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }